<template>
    <div class="bg vh">
    <van-nav-bar title="专家抽取" left-arrow @click-left="$router.push('/home')" />
    <div style="position: relative;">
        <p class="templine"></p>
        <div class="flex-between tt whiteBg">
            <div>
                <p :class="activeIndex>=1 ? 'active':''">1</p>
                <p :class="activeIndex ==1 ? '' : 'noActive'">项目录入</p>
            </div>
            <div>
                <p :class="activeIndex>=2 ? 'active':''">2</p>
                <p :class="activeIndex ==2 ? '' : 'noActive'">项目抽取</p>
            </div>
            <div>
                <p :class="activeIndex>=3 ? 'active':''">3</p>
                <p :class="activeIndex ==3 ? '' : 'noActive'">抽取结果</p>
            </div>
        </div>
    </div>

    <router-view class="mar-t2" @onActive="onActive" />
  </div>
</template>

<script>
export default {
    data(){
        return{
            activeIndex : 1
        }
    },

    methods: {
        onActive(index){
            this.activeIndex = index
        }
    }

}
</script>

<style scoped>
.tt>div{width: 33.33%; position: relative; z-index: 10; display: flex;display: -webkit-flex;align-items: center; flex-direction: column; justify-content: center;}
.tt>div>p{text-align: center; font-weight: bold;}
.tt>div>p:first-child{color: white; background-color: #80E6D9;width: 0.6rem; height: 0.6rem; border-radius: 50%;}
.tt>div>p:last-child{font-size: 0.35rem;}
.tt>div>p[class='active']{background-color: #00CCB2;}
.templine{background-color: #E1FFFB;height: 0.1rem;width: 70%;position: absolute; margin-left: 15%;top: 0.25rem;}
.noActive{color: #A6A6A6;}

</style>